@use '../variables' as *;

$panelHeight: 28em;

.notification + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    min-width: 28em;

    .mat-mdc-menu-content {

      mat-nav-list {
        padding-block: 0;

        .notification-box {

          span {
            color: #000;
          }

          .seen {
            span {
              opacity: 0.7;
            }
          }

          .time-ago {
            opacity: 0.8;
          }

          .unread-circle {
            width: 8px;
            height: 8px;
            border-radius: 50px;
            background-color: $color-red;
            display: block;
          }
        }

        mat-list-item {
          width: 100%;
          height: 75px !IMPORTANT;

          &:not(:first-child) {
            border-top: 1px solid #d2d6dc66;
          }

          span {
            font-size: 14px;
          }
        }

        .title {
          font-size: 16px;
          font-weight: bold;
          margin-left: 16px;
        }

        cdk-virtual-scroll-viewport {
          height: $panelHeight;
          min-width: 28em;

          .cdk-virtual-scroll-content-wrapper {
            .noti-text {
              width: 100%;
              height: 75px !IMPORTANT;
              font-weight: 500;
            }
            .empty, .spinner {
              height: $panelHeight;
              display: grid;
              place-content: center;
            }
          }
        }
      }
    }

  }
}
