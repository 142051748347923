@use '../variables' as *;
@use '../mixin/background' as bg;
@use '../mixin/flex' as f;
@use '../mixin/break-point' as b;
@use '../functions/pixel-to-rem' as func;
@use '../mixin/transition' as t;

$height: 100dvh;

@mixin responsivePage() {
  aside {
    width: 0;
    opacity: 0;
    @include t.transition(all, 300ms, ease);

    & > *,
    img {
      width: 0;
      opacity: 0;
      @include t.transition(all, 300ms, ease);
    }
  }

  main {
    z-index: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
  }
}

.auth-page {
  .full-width {
    width: 100%;
  }
  .color-primary {
    color: $color-primary;
  }
  .pointer {
    cursor: pointer;
  }
  .color-blur {
    opacity: 0.5;
  }

  h1 {
    margin-bottom: func.rem(8);
  }

  h3 {
    margin-bottom: func.rem(24);
  }

  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .flex-col {
    flex-direction: column;
  }

  .mat-button-custom {
    padding: 26px 36px !important;
    border-radius: 50px;
  }

  .text-warn {
    color: $color-required;
  }

  .ml-auto {
    margin-left: auto;
  }

  .full-height {
    height: $height;
    overflow: hidden;

    .content-wrapper {
      background-color: $color-primary;
    }

    .grid {
      height: inherit;
    }

    aside {
      position: relative;
      opacity: 1;
      width: 100%;
      flex: 1;
      @include t.transition(all, 300ms, ease);

      & > * {
        color: #fff;
      }

      img {
        &.bg-login {
          width: 100%;
          height: inherit;
          object-fit: cover;
        }
      }

      .logo-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        width: 100%;

        .logo-title {
          width: 15em;
          white-space: break-spaces;
          font-family: 'Moul', 'Khmer', sans-serif;
          line-height: normal;
          color: #fff;
          font-size: 16px;
          cursor: pointer;

          .main-title {
            font-size: 18px;
          }
        
          .main-subtitle {
            font-size: 14px;
          }
        }

        .logo-center {
          display: grid;
          place-items: center;
          width: inherit;

          img {
            width: 100%;
            height: 100%;
            max-width: 375px;
            max-height: 375px;
          }

          .login-desc {
            display: grid;
            place-items: center;
            text-align: center;
          }
        }
      }
    }

    main {
      &.form-wrapper {
        position: relative;
        height: $height;
        min-height: $height;
        overflow-y: auto;
        overflow-x: hidden;
        width: func.rem(765);
        padding: 1em;
        background-color: #fff;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-left: auto;

        .logo-wrapper {
          display: none;
        }

        h1 {
          margin-bottom: 0;
          font-weight: bold;
          color: #525252;
          font-size: 30px;
          line-height: normal;
        }

        &.register-form {
          width: func.rem(1120);

          .animate-content {
            margin-block: 0;
          }

          mat-stepper {
            .mat-horizontal-stepper-wrapper {
              .mat-horizontal-stepper-header-container {
                margin-inline: auto;
                width: 100%;
                max-width: 34em;

                .mat-stepper-horizontal-line {
                  border: none;
                  border-bottom: 2px dashed #9e9e9e;
                }
              }
            }
          }

          .verification {
            height: calc(100vh - 6em);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text-colored {
              color: $color-primary;
            }

            .button-verify {
              border-radius: 10px !important;
              height: 35px !important;
              padding-inline: 1.5em !important;
              margin-right: 0.6em;

              mat-icon {
                min-width: 1.125rem;
              }

              &.button-re-verify {
                background-color: $aluminium;
                pointer-events: none;
                cursor: none;
              }
            }

            .mdc-button {
              &__label {
                letter-spacing: 0;
                min-width: fit-content;
              }
            }

            ngx-otp-input {
              &.error {
                input {
                  border: 2px solid $color-required;
                }
              }

              input {
                border: 1px solid #00000026;
                height: 56px;
                width: 52px;
                border-radius: 8px;
              }
            }

            re-captcha {
              #rc-anchor-container {
                border-radius: 10px;

                label {
                  font-family: 'Inter, Khmer, system ui' !important;
                  font-size: 10px;
                }
              }
            }
          }

          .stepper-action-bottom {
            button {
              padding: 26px 36px !important;
              border-radius: 50px;

              &:not(:disabled) {
                border: 1px solid $color-primary;
              }
            }
          }
        }
        @include t.transition(width, 300ms, ease-out);

        &.login-form {
          display: flex;
          flex-direction: column;
        }

        &.create-form {
          .verification {
            margin-top: 56px;
            .text-colored {
              color: $color-primary;
            }

            .button-continue {
              button {
                width: 100%;
                border-radius: 36px;
                height: 53px !important;
              }
            }

            .button-verify {
              border-radius: 10px !important;
              height: 35px !important;
              padding-inline: 1.5em !important;
              margin-right: 0.6em;

              mat-icon {
                min-width: 1.125rem;
              }

              &.button-re-verify {
                background-color: $aluminium;
                pointer-events: none;
                cursor: none;
              }
            }

            .mdc-button {
              &__label {
                letter-spacing: 0;
                min-width: fit-content;
              }
            }

            ngx-otp-input {
              display: grid;
              place-content: center;

              &.error {
                input {
                  border: 2px solid $color-required;
                }
              }

              input {
                border: 1px solid #00000026;
                height: 56px;
                width: 52px;
                border-radius: 8px;
              }
            }

            re-captcha {
              #rc-anchor-container {
                border-radius: 10px;

                label {
                  font-family: 'Inter, Khmer, system ui' !important;
                  font-size: 10px;
                }
              }
            }
          }
        }

        .lang-wrapper {
          position: absolute;
          right: 0;
          z-index: 1;
          top: 1.5%;

          .languages {
            padding: 0 !important;
            box-shadow: none;
            background-color: #fff !important;

            &:focus,
            &:hover {
              box-shadow: none !important;
            }

            mat-icon {
              height: 2.425rem;
              width: 2.425rem;
              margin-right: 0;
            }
          }
        }

        @media screen and (min-width: 600px) {
          padding: 3em;

          .lang-wrapper {
            top: 4.5%;
          }
        }

        .creation-form {
          background-color: transparent;
          padding: 0;
        }

        .relative {
          position: relative;
        }

        .animate-content {
          height: fit-content;
          place-content: center;
          margin: auto;
          width: 100%;
        }

        .v-line {
          width: 210px;
          height: 1px;
          display: block;
          background-color: #dddddd;
        }

        .social-login-button {
          position: relative;
          display: grid;
          gap: 1em;
          place-items: center;

          & > button,
          .telegram-log {
            width: 100%;
            max-width: 498px;
            height: 56px !important;
            border-radius: 50px;
            border: 1px solid #e2e8f0;
            padding: 16px;
          }

          .telegram-login-wrapper {
            position: relative;
            width: 100%;
            max-width: 498px;
            height: 56px;
            overflow: hidden;

            .telegram-log {
              position: absolute;
              inset: 0;
              z-index: 0;

              &.hover {
                background-color: #f5f5f5;
              }
            }

            app-telegram-login-widget {
              display: block;
              position: relative;
              z-index: 1;
              width: 100%;
              max-width: 498px;
              height: 56px;
              opacity: 0.0001;

              #telegram-login-widget {
                iframe {
                  scale: 2.3;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1445px) {
    .full-height {
      .content-wrapper {
        &.register-wrapper {
          @include responsivePage();
        }
      }

      main {
        &.form-wrapper {
          &.register-form {
            .logo-wrapper {
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1090px) {
    .full-height {
      .content-wrapper {
        @include responsivePage();
      }

      main {
        &.form-wrapper {
          &.login-form {
            .logo-wrapper {
              display: block;
            }
          }
        }
      }
    }
  }
}

.password-helper {
  &.mat-mdc-menu-panel {
    border-radius: 10px !important;
  }
}

.reset-password {
  height: calc(100vh - 6em);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text-colored {
    color: $color-primary;
  }

  .button-verify {
    border-radius: 10px !important;
    height: 35px !important;
    padding-inline: 1.5em !important;
    margin-right: 0.6em;

    .mdc-button {
      &__label {
        letter-spacing: 0;
        min-width: fit-content;
      }
    }

    mat-icon {
      min-width: 1.125rem;
    }

    &.button-re-verify {
      background-color: $aluminium;
      pointer-events: none;
      cursor: none;
    }
  }

  .mdc-button {
    &__label {
      letter-spacing: 0;
    }
  }

  ngx-otp-input {
    &.error {
      input {
        border: 2px solid $color-required !important;
      }
    }

    input {
      border: 1px solid #00000026 !important;
      height: 56px !important;
      width: 52px !important;
      border-radius: 8px !important;
    }
  }

  .logo-in-mobile {
    display: none;
  }

  @media screen and (max-width: 1090px) {
    .logo-in-mobile {
      display: block;
    }
  }
}
