@use '../variables' as *;

$imageWidth: 465px;

.request-service-wrapper {
  padding: 32px;

  .color-warn {
    color: $color-required;
  }

  .color-blur {
    opacity: 0.6;
  }

  .card-wrapper {
    border: 1px solid $border-grey;
    border-radius: 16px;

    .header-label {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .stepper-action-bottom {
    button {
      border: 1px solid $color-primary;
      padding: 26px 36px !important;
      border-radius: 50px;
    }
  }

  .admin-service-info {
    .add-doc-action {
      button {
        width: 30px !important;
        height: 30px !important;
        display: grid;
        place-items: center;
        padding: 0;

        mat-icon {
          scale: 0.65;
        }
      }
    }

    .exist-app-wrapper {
      background: #00000008;
      border-radius: 10px;

      .exist-req-button {
        color: $color-primary;

        span {
          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    .textarea-auto-height-form {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            padding-block: 8px;
          }
        }

        textarea {
          align-content: center;
        }
      }
    }
  }

  .fill-out-form-wrapper {
    .attachment {
      .header-label {
        margin-bottom: 0;
      }

      .full-width {
        width: 100%;
      }

      .status-label {
        padding: 8px 12px;
        border-radius: 50px;

        &.done {
          color: $salem;
          background: rgba($color: $salem, $alpha: 0.1);
        }

        &.warning {
          color: $sea-buckthorn;
          background: rgba($color: $sea-buckthorn, $alpha: 0.1);
        }
      }

      &__form-wrapper {
        width: 100%;

        .card {
          border-top: 1px solid $border-grey;

          &:not(:last-child) {
            padding-block: 16px;
          }

          &:last-child {
            padding-top: 16px;
          }

          .color-blur {
            opacity: 0.6;
          }
        }

        .status-icon {
          &.color-primary {
            mat-icon {
              display: grid;
              place-items: center;
              background-color: #20c374;
              color: #fff;
              border-radius: 100%;
              font-size: 20px;
            }
          }

          .icon-require {
            filter: brightness(0) saturate(100%) invert(41%) sepia(78%) saturate(513%) hue-rotate(312deg)
              brightness(116%) contrast(102%);
          }

          mat-icon {
            width: 32px;
            height: 32px;
          }
        }

        .required-sign {
          &::after {
            content: ' *';
            color: $color-required;
          }
        }

        .required-text {
          padding: 10px;
          border-radius: 4px;
          background-color: rgba($color: $color-active, $alpha: 0.1);
          color: $color-active;
        }

        .group-btn {
          gap: 0;
        }
      }
    }

    .accept-principle {
      &.ng-touched:not(.mat-mdc-checkbox-checked) {
        &:not(.mat-mdc-checkbox-checked) {
          .mdc-label {
            color: $color-red;
          }

          .mdc-checkbox__background {
            --mdc-checkbox-unselected-icon-color: #ff0000;
            --mdc-checkbox-unselected-focus-icon-color: #ff0000;
          }
        }
      }
    }
  }

  .payment-wrapper {
    .payment {
      &__method {
        mat-nav-list {
          mat-list-item {
            height: 68px;
            border-radius: 10px;
            border: 1px solid transparent;

            img {
              border-radius: 10px;
            }

            &.active {
              border-color: $color-primary;
            }
          }
        }

        .mdc-form-field {
          .mdc-checkbox {
            &__background {
              border-radius: 50px;
            }

            &__ripple {
              border-radius: 50px;
            }
          }

          label {
            color: #000;
          }
        }

        .method-info-wrapper {
          & > span, strong {
            white-space: break-spaces;
          }
        }
      }
    }

    .payment-method-wrapper {
      .method-wrapper {
        background-color: #117ec114;
        border-radius: 10px;

        mat-nav-list {
          padding-block: 0;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    padding: 0;
    padding-bottom: 16px;

    .stepper-action-bottom {
      padding-inline: 16px;
    }
  }
}

.request-info-wrapper {
  .user-info-wrapper {
    .status-card {
      padding: 12px;
      border: 1px solid $salem;
      border-radius: 5px;
      background-color: rgba($color: $salem, $alpha: 0.1);

      &.requesting {
        background-color: rgba($color: $color-active, $alpha: 0.1);
        border-color: $color-active;
      }

      &.rejected,
      &.return_request {
        background-color: rgba($color: $color-light-red, $alpha: 0.1);
        border-color: $color-light-red;
      }

      &.draft {
        background-color: rgba($color: $color-grey, $alpha: 0.1);
        border-color: $color-grey;
      }
    }
  }

  .request-timeline {
    .flex-1 {
      flex: 1;
    }

    .text-blur {
      opacity: 0.6;
    }

    .timeline-section {
      min-height: 135px;

      .mobile-off {
        min-width: 12em;
      }

      .line-warpper {
        position: relative;

        .circle {
          display: block;
          background-color: #fff;
          height: 40px;
          width: 40px;
          border: 4px solid transparent;
          border-radius: 100%;
          display: grid;
          place-items: center;
          outline: 1px dashed $salem;

          mat-icon {
            scale: 0.85;
          }

          &.quit,
          &.rejected,
          &.return_request,
          &.suspended {
            outline-color: $color-light-red;
          }

          &.requesting,
          &.pending {
            outline-color: $sea-buckthorn;
          }

          &.draft {
            outline-color: $color-grey;

            mat-icon {
              scale: 1;
            }
          }
        }

        .horizontal-line {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;

          .line {
            border-left: 2px solid $salem;
            height: inherit;
            display: block;
          }
        }
      }

      .create_by {
        display: flex;
        flex-direction: column;
        flex: 1;

        &__status {
          .mobile-on {
            display: none;
          }

          .timeline-title {
            font-size: 16px;
          }
        }

        &__quit-reason {
          width: fit-content;

          &__wrapper {
            background-color: $black-squeeze-light;
          }
        }
      }

      .reason-rejected,
      .reason-return_request,
      .reason-suspended {
        .create_by {
          &__quit-reason {
            &__wrapper {
              background-color: rgba($color: $color-light-red, $alpha: 0.1);
              span {
                color: $color-light-red;
              }
            }
          }
        }
      }

      .reason-requesting {
        .create_by {
          &__quit-reason {
            &__wrapper {
              background-color: rgba($color: $sea-buckthorn, $alpha: 0.1);
              span {
                color: $sea-buckthorn;
              }
            }
          }
        }
      }

      @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        .mobile-off {
          display: none;
        }

        .create_by {
          &__status {
            .mobile-on {
              display: block;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}
