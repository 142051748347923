@use '../variables' as *;
@use '../mixin/transition' as t;

$img-width: 20em;

.images-upload-drag {
  &.required-file {
    .upload-card {
      border-color: $color-light-red;
    }
  }

  .upload-card {
    border: 2px dashed $mercury-color;
    overflow: hidden;
    border-radius: 10px;
    min-height: 205px;
    height: 100%;
    align-content: center;

    .relative-div {
      height: inherit;
      display: grid;
      place-content: center;

      .required-text {
        color: #53535f66;
      }

      .file-wrapper {
        position: relative;

        .relative {
          position: relative;
        }

        .file-doc {
          mat-icon {
            min-width: 24px;
            max-width: 24px;
          }
        }

        .mat-icon.btn-remove {
          position: absolute;
          top: -15px;
          right: calc(0% - 12px);
          background-color: $color-light-red;
          border-radius: 50%;
          color: white;
          cursor: pointer;
        }

        .image-cover {
          width: $img-width;
          height: auto;
          display: flex;
          justify-content: center;
          padding-inline: 20px;
          padding-bottom: 20px;

          img {
            max-inline-size: 100%;
            block-size: auto;
            inline-size: 100%;
          }
        }

        .text-secondary {
          width: $img-width;
          overflow-wrap: anywhere;
        }

        .profile-picture {
          margin-left: auto;
          margin-right: auto;

          &.svg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    .file-card-wrapper {
      display: grid;
      row-gap: 1em;

      &.file-item-center {
        height: inherit;
        place-content: center;
      }

      .file-card {
        background-color: rgba($color: $color-primary, $alpha: 0.06);
        border-radius: 10px;
      }
    }

    .image-wrapper {
      width: 100%;
      height: 100%;
      display: grid;
      place-content: center;

      .image {
        width: 285px;
        height: 175px;
        position: relative;

        .mat-mdc-mini-fab {
          position: absolute;
          right: 0;
          top: 0;
          translate: 12px -12px;
        }

        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
    }
  }

  .btn-browse {
    font-weight: 700;
    cursor: pointer;
  }

  .invalid .drop-zone {
    border-color: $color-light-red;
  }

  .note {
    color: $aluminium;
    font-size: 12px;
  }

  .drop-zone {
    &.fileover {
      border: 2px dashed $color-primary;
    }

    .icon-upload {
      font-size: 80px;
      margin-top: 10px;
      // margin-bottom: 10px;
      user-select: none;
      color: $color-secondary;
    }

    .btn-browse {
      color: $color-primary;
      cursor: pointer;
    }

    p {
      margin-top: 0;
    }

    .file-name {
      overflow: hidden;
      text-overflow: ellipsis;
      // padding: 0 10px;
    }
  }

  .content-thumbnail {
    width: 38px;
    height: 34px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    margin: {
      left: auto;
      right: auto;
    }

    &.video,
    &.file {
      height: 150px;
      width: 150px;
      cursor: pointer;
      border-radius: 100%;
    }
  }

  .dragCursor {
    cursor: move;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);

    background-color: white;
  }

  .cdk-drag-preview .mat-row {
    padding: 0 14px !important;
  }

  .cdk-drag-placeholder {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      background: #ccc !important;
      border: dotted 3px #999;
      min-height: 60px;
      @include t.transition(transform, 250ms, cubic-bezier(0, 0, 0.2, 1));
    }
  }

  .cdk-drag-animating {
    @include t.transition(transform, 250ms, cubic-bezier(0, 0, 0.2, 1));
  }

  .cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
    @include t.transition(transform, 250ms, cubic-bezier(0, 0, 0.2, 1));
  }

  .file-content {
    position: relative;
    display: inline-block;

    &:hover {
      .clear-btn {
        display: block;
      }
    }

    .clear-btn {
      display: none;
      position: absolute;
      right: 0;
      transform: translate(0);
      background-color: $color-light-red;
      color: white;
      cursor: pointer;
      border-radius: 50%;
      border: 2px solid;
      box-sizing: content-box;
    }
  }

  .browse-file {
    cursor: pointer;
  }

  content-required {
    border-color: $color-required;
  }

  .reorder-btn-container {
    justify-content: space-between;
  }
}
