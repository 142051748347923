@use '../variables' as *;
@use '../functions/pixel-to-rem' as f;
@use '../mixin/transition' as t;

$maximum-width: 80%;
$responsive-width: 90%;

.app-menu {
  position: relative;

  .standard-container {
    width: $maximum-width !important;
    margin: 0 auto;

    @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
      width: $responsive-width !important;
    }
  }

  .app-container {
    height: 100vh;
    background: #ffffff;

    mat-sidenav {
      width: 250px;
      background: #ffffff;

      .main-logo {
        cursor: pointer;
        height: 120px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        z-index: 2;
        position: absolute;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);

        .logo-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          place-content: center;
          margin: auto;
          color: #fff;
          font-weight: 600;
          padding: 6px 0;

          img {
            object-fit: cover;
          }
        }
      }
    }

    mat-sidenav-content {
      overflow-x: hidden;

      .app-root-content {
        height: 100dvh;
        overflow: auto;

        .dashboard-toolbar {
          // overflow-x: hidden;
          display: flex;
          flex-direction: column;
          background: transparent;
          z-index: 2;
          position: absolute;
          padding: 0;
          height: auto;

          &.blurred {
            background-color: transparent;
          }

          .bg-color {
            position: absolute;
            inset: 0;
            background-image: $linear-gradient-primary;
            height: 0;
            @include t.transition(height, 300ms, ease-out);

            &.show {
              height: 100%;
              @include t.transition(height, 300ms, ease-out);
            }
          }

          .dashboard__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 0;
            min-height: 70px;
            width: 100%;

            img {
              // height: auto;
              cursor: pointer;
              z-index: 1;
            }

            h1 {
              margin: 0;
              padding: 0;
              font-weight: 400;
              flex: 1;
              color: #fff;
              font-size: 18px;
              cursor: pointer;
            }

            .icon {
              background-repeat: no-repeat;
              background-position: center;
              color: #fff;
            }

            .menu-list {
              display: flex;
              align-items: center;
              padding: 0;

              .has-child {
                position: relative;

                &:hover {
                  mat-nav-list {
                    height: fit-content;
                    opacity: 1;
                    height: 137px;

                    > mat-list-item {
                      height: 45px !important;
                      opacity: 1 !important;
                      @include t.transition(all, 300ms, ease-out);
                    }
                  }
                }

                mat-nav-list {
                  padding-block: 0;
                  position: absolute;
                  top: 45px;
                  left: 0;
                  height: 0;
                  opacity: 0;
                  @include t.transition(all, 300ms, ease-out);
                  background-color: #fff;
                  border-radius: 10px;
                  box-shadow:
                  0px 11px 15px -7px rgba(0, 0, 0, 0.2),
                  0px 24px 38px 3px rgba(0, 0, 0, 0.14),
                  0px 9px 46px 8px rgba(0, 0, 0, 0.12);

                  > mat-list-item {
                    height: 0 !important;
                    opacity: 0 !important;
                    @include t.transition(all, 300ms, ease-out);
                  }

                  mat-list-item {
                    border-radius: 0;
                    opacity: 1;

                    :hover {
                      span {
                        color: $color-primary;
                      }
                    }

                    &.active {
                      background-color: rgba($color: $color-primary, $alpha: 1);
                      opacity: 1;
                      span {
                        color: #fff;
                      }
                    }

                    span {
                      font-size: 14px;
                    }

                    mat-icon {
                      width: initial;
                    }
                  }
                }
              }

              mat-list-item {
                border-radius: 8px;
                opacity: 0.6;

                &:hover {
                  opacity: 1;
                }

                &.active {
                  opacity: 1;
                }

                .menu-item-title {
                  color: #fff;
                }

                mat-icon {
                  width: 12px;
                }
              }
            }
          }

          .header-action {
            display: flex;
            align-items: center;

            button {
              span {
                color: #fff;
              }
            }

            .languages {
              box-shadow: none;
            }

            .vertical-line {
              height: 45px;
              width: 1px;
              background-color: #f6f6f61a;
              position: relative;
              display: block;
              margin-inline: 4px;
            }

            .avatar {
              .profile-menu-btn {
                border-radius: $rounded-sm;
                padding: 4px;

                .mdc-button {
                  &__label {
                    line-height: 1;
                  }
                }
              }
            }
          }
        }

        .dash-row {
          display: block;
          width: 100%;
          overflow: auto;
          margin: 0 auto;
          min-height: 100dvh;
          background-color: #fff;
          z-index: 1;
          position: relative;
        }

        .scroll-to-top {
          position: fixed;
          z-index: 99;
          bottom: 1.5em;
          right: 2.5em;

          @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
            right: 1.5em;
          }
        }
      }
    }

    .mat-form-field-flex {
      width: 100%;
    }

    .no-wrap {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      padding-right: 50px !important;
    }

    .mat-mdc-list-base .mat-mdc-list-item.mat-mdc-list-item-single-line,
    .mat-mdc-list-base .mat-mdc-list-option.mat-mdc-list-item-single-line {
      height: 45px !important;
      display: flex;
      align-items: center;
      width: 100%;
    }

    #list-nav {
      overflow-x: hidden !important;
      overflow-y: auto !important;
      padding-top: unset !important;
      margin-top: 120px;

      .mat-mdc-list-item {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
        }

        &.rounded-0 {
          border-radius: 0;
        }

        .mat-mdc-list-item-unscoped-content {
          width: 100%;

          .menu-item-title {
            padding-left: 20px;
            line-height: normal;
            font-weight: 400;
          }
        }
      }

      .mat-list-text {
        display: none !important;
      }
    }

    .example-sidenav {
      /* width */
      ::-webkit-scrollbar {
        width: 0px !important;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888 !important;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
  }
}

.hover-profile + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    border-radius: $rounded-sm;

    .profile-img {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: initial;
      min-width: 12em;
      height: 70px;
      min-height: 70px;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: transparent !important;
      }

      .avatar-profile {
        width: f.rem(48);
        height: f.rem(48);
      }

      .name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .role {
        font-size: 12px;
        color: $scarpa-flow;
      }

      &:hover {
        background-color: $black-squeeze-color;
      }
    }
  }
}

.nav-mat-menu + * .cdk-overlay-pane {
  margin-left: 6em;

  .mat-mdc-menu-panel {
    overflow-x: hidden;
    border-radius: $rounded-sm;

    mat-nav-list {
      padding: 0;

      .active {
        background-color: rgba($color: $color-primary, $alpha: 1);

        .menu-item-title {
          color: #fff;
        }
      }

      .menu-item-title {
        font-size: 14px;
        border-radius: $rounded-md;
      }
    }
  }
}

.nav-mat-menu-child + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    button {
      min-width: 12em;
    }

    mat-nav-list {
      padding: 0;
      .active {
        background-color: rgba($color: $color-primary, $alpha: 1);
        --mdc-list-list-item-label-text-color: #fff;
      }

      .menu-item-title {
        font-size: 14px;
        border-radius: $rounded-md;
      }
    }
  }
}
