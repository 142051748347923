@use '../variables';

.app-loading {
  z-index: 1050;
  position: fixed;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: #3333;

  .loader-wrapper {
    width: 100px;
    height: 100px;
    position: absolute;
  }

  .loader {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 10px solid transparent;
    border-top-color: #00aa81;
    border-bottom-color: #0060ef;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
  }

  .loader-inner {
    border-top-color: #d51da7;
    border-bottom-color: #ffb750;
    animation-duration: 2.5s;
  }

  @keyframes rotate {
    0% {
      transform: scale(1) rotate(360deg);
    }

    50% {
      transform: scale(.8) rotate(-360deg)
    }

    100% {
      transform: scale(1) rotate(360deg)
    }
  }
}

.app-content {
  //background-color: white;
  border-radius: variables.$rounded-sm;
  max-width: 2000px;
  margin: 0 auto;
  padding-block: variables.$spacing-md;
  padding-inline: variables.$spacing-md;

  @media screen and (min-width: map-get($map: variables.$grid-breakpoints, $key: 'md')) {
    padding-inline: variables.$spacing-md * 2;
  }

  .app-menu-title {
    position: absolute;
    top: 26px;
    left: calc(48 + (48 / 2)) + px;
    z-index: 9999;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.05rem;
  }

  .container{
    background-color: white;
    border-radius: variables.$rounded-sm;
  }
  .app-title {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    flex-wrap: wrap;
    gap: 16px;

    .title {
      display: grid;
      font-size: 22px;
      font-weight: 600;
      grid-gap: 6px;
      line-height: unset;
      color: variables.$scarpa-flow;

      button {
        font-size: 22px;
        padding: 2px;
        font-weight: 600;
      }

      .name {
        margin: 0;
        color: variables.$silver-chalice;
      }
    }

    .arrow-back {
      padding: 0;

      .title-arrow {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span.naming {
          color: variables.$silver-chalice;
        }
      }
    }
  }

  .create-btn,
  .group-btn {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
