@use '../variables';
@use '@angular/material' as mat;

.mat-mdc-form-field {
  display: block !important;

  &.mat-form-field-appearance-outline {

    &.subscript-hide {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    /* input */
    .mat-mdc-form-field-infix {
      min-height: 48px;
      align-content: center;
      padding-block: 0;
      z-index: 1;

      app-error-message {
        position: absolute;
        left: 0;
        top: 34px;
      }
    }

    .mat-mdc-input-element:disabled {
      color: variables.$color-black;
    }

    .mat-mdc-select-disabled .mat-mdc-select-value {
      color: variables.$color-black;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline__leading {
          border-radius: variables.$rounded-sm 0 0 variables.$rounded-sm !important;
        }
        .mdc-notched-outline__trailing {
          border-radius: 0 variables.$rounded-sm variables.$rounded-sm 0 !important;
        }
      }

      &:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid).mdc-text-field {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          border-color: variables.$black-squeeze-color !important;
        }
      }

      &.mdc-text-field--invalid {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-width: 2px;
        }
      }
    }

    /* subscript */
    .mat-mdc-form-field-subscript-wrapper {
      margin-top: 0;
    }

    /* date picker */
    .mat-mdc-datepicker-toggle {
      color: variables.$color-primary;
    }

    /* select */
    .mat-mdc-select-arrow {
      opacity: 0;
    }

    .mat-mdc-form-field-prefix {
      color: variables.$color-grey;
    }
  }

  &.mat-form-field-disabled {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          background-color: variables.$color-form-disabled !important;
        }
      }

      .mat-mdc-form-field-infix {
        input {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.mat-mdc-form-field-type-mat-select {
  mat-icon {
    &:not(.material-icons) {
      width: 10px;
      height: 16px;
    }
  }
}
