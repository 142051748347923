@use '../variables' as *;
@use '../mixin/transition' as t;

mat-stepper {
  &.mat-stepper-vertical {
    .mat-stepper-vertical-line {
      &.stepper-line-colored {
        &::before {
          border-left: 2px solid $color-primary !important;
          @include t.transition(border-bottom, 250ms, ease-in-out);
        }
      }
    }
  }

  .mat-horizontal-stepper-wrapper {
    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-horizontal-stepper-header-container {
      .mat-stepper-horizontal-line {
        &.stepper-line-colored {
          border-bottom: 2px solid $color-primary !important;
          @include t.transition(border-bottom, 250ms, ease-in-out);
        }
      }

      mat-step-header {
        .mat-step-icon {
          transform: scale(1.2);
          margin-right: 16px;

          mat-icon {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
