@use 'color' as c;

a,
a:visited,
a:active {
  color: c.$color-text;
  text-decoration: none;

  &.cursor-pointer {
    cursor: pointer;
  }
}
