@use '../variables' as *;
@use '../mixin/transition' as t;

$input-search-height: 70px;

$standard-width: 80%;

@mixin inputSearch() {
  // format mat form field style
  .mat-mdc-form-field-infix {
    min-height: 60px;
  }
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      padding-inline: 8px;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-width: 2px;
      }

      .mdc-notched-outline__leading {
        border-radius: 50px 0 0 50px !important;
      }
      .mdc-notched-outline__trailing {
        border-radius: 0 50px 50px 0 !important;
      }
    }
  }
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 28px;
  }
}

.landing-page {
  overflow-y: hidden;

  .color-blur {
    opacity: 0.8;
  }

  .text-color {
    color: $color-primary;
  }

  mat-divider {
    --mat-divider-color: #0000000d;
  }

  p {
    margin-bottom: 0;
  }

  p, span {
    word-break: break-word;
  }

  @for $var from 10 through 25 {
    .font-size-#{$var} {
      font-size: $var * 1px;
    }
  }

  .title-section {
    font-size: clamp(25px, 3.6vw, 35px);
    line-height: 1.5em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .sub-content {
    padding-block: clamp(2.5em, 6.5vw, 10em);
  }

  .content {
    &__top {
      min-height: 532px;
      height: 100%;
      width: 100%;
      align-content: center;
      background: url('/assets/imgs/bg-homepage.svg') no-repeat;
      background-size: cover;
      display: grid;

      & > * {
        color: #fff;
      }

      .content-wrapper {
        z-index: 1;
        width: 80%;
        height: inherit;
        margin-inline: auto;
        place-content: center;
        place-items: center;

        h1 {
          font-size: clamp(25px, 3.6vw, 35px);
          font-weight: 700;
          line-height: 1.5em;
        }

        .top-desc {
          font-size: clamp(14px, 1.5vw, 16px);
          word-break: break-word;
        }

        .statistic {
          .number > * {
            font-size: clamp(18px, 1.6vw, 25px);
          }

          span {
            margin-top: 8px;
            font-size: clamp(14px, 1.5vw, 16px);
          }

          mat-icon {
            min-width: 35px;
            height: 35px;
          }

          .vertical-line {
            height: 20px;
            width: 2px;
            background-color: #a8c3ff;
            position: relative;
            display: block;
          }

          .amount {
            min-width: 12em;
          }
        }

        .mdc-button {
          &.button-request {
            height: clamp(48px, 6.5vw, 66px) !important;
            border-radius: 50px;

            mat-icon {
              min-width: 2rem;
              scale: 1.4;
            }
          }

          &.button-search {
            border-color: #fff;
            color: #fff;
          }

          &__label {
            font-size: clamp(14px, 1.6vw, 16px);
          }
        }
      }
    }

    &__admin-service {
      padding-bottom: clamp(2.5em, 6.5vw, 10em);

      .container-wrapper {
        .title-section {
          text-align: left;
        }

        @include inputSearch();

        .card-wrapper {
          .card {
            box-shadow: 0px 4px 25px 0px #0000000d;
            @include t.transition(all, 300ms, ease);
            cursor: pointer;

            &:hover {
              box-shadow: none;
              transform: translateX(1em);
            }
          }

          .vertical-line {
            height: 20px;
            width: 1px;
            background-color: $border-grey;
            position: relative;
            display: block;
          }

          .button-view {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid $color-primary;
            border-radius: 8px;
            width: 35px;
            height: 35px;

            mat-icon {
              color: $color-primary;
              scale: 0.9;
            }
          }
        }
      }

      .hide-filter {
        display: none;
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      mat-icon {
        height: 24px;
        width: 24px;
        min-width: 24px;
      }

      .input-search-wrapper {
        transform: translateY(-35px);

        .input-search {
          .button-send-filter {
            border-radius: 50px;
            width: 125px;
            height: 50px !important;

            &.mat-mdc-button-disabled {
              mat-icon {
                margin-right: 0;
              }
            }
          }

          .mat-mdc-form-field {
            position: relative;
            border-radius: 50px;
            box-shadow: 0px 4px 25px 0px #0000001a;

            .mat-mdc-select-value {
              text-overflow: unset;
            }

            &.mat-ripple {
              background-color: #fff;
            }

            &.mat-form-field-appearance-outline {
              .mat-mdc-form-field-infix {
                min-height: $input-search-height;

                input {
                  font-size: 16px;
                  min-height: inherit;
                  line-height: normal;
                }
              }

              .mat-mdc-text-field-wrapper {
                padding-inline: 16px;

                .mat-mdc-form-field-flex {
                  border-radius: 50px;

                  .mdc-notched-outline__leading {
                    border-radius: 50px 0 0 50px !important;
                    width: 50px;
                    background-color: #fff;
                    border: none !important;
                  }

                  .mdc-notched-outline__trailing {
                    border-radius: 0 50px 50px 0 !important;
                    background-color: #fff;
                    border: none !important;
                  }
                }
              }

              //!filter action at right side of input
              .filter-action {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                background: #fff;
                border-radius: 50px;

                .vertical-line {
                  height: 70%;
                  width: 1px;
                  background: $athens-gray-color;
                }
              }
            }
          }
        }
      }

      .filter-mobile {
        box-shadow: 0px 4px 25px 0px #0000001a;
        padding: 1em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
      }
    }

    &__step-request {
      width: 80%;

      .container-wrapper {
        min-height: 255px;

        .card-wrapper {
          .card {
            justify-content: center;
            align-items: center;

            article {
              align-content: center;
              height: 255px;
              width: 290px;
              text-align: center;
              padding: 2em;
              border-radius: 10px;

              &:hover {
                background-color: rgba($color: $color-primary, $alpha: 0.05);
              }
            }

            &:not(.arrow) {
              img {
                &:not([data-src='arrow']) {
                  padding: 1.5em;
                  box-shadow: 0px 4px 25px 0px #0000000d;
                  border-radius: 10px;
                }
              }
            }

            .card-title {
              margin-top: 2em;
              font-weight: bold;
              font-size: clamp(14px, 1.5vw, 16px);
            }

            .card-desc {
              margin-top: 0.5em;
              opacity: 0.8;
            }
          }
        }
      }
    }

    &__contact {
      .section-wrapper {
        gap: clamp(1em, 8.5vw, 10em);

        .map {
          align-content: center;

          iframe {
            width: 100%;
            max-width: 678px;
            height: 400px;
          }
        }

        .address {
          margin-top: clamp(2.5em, 6.5vw, 5em);

          strong {
            font-size: clamp(14px, 1.5vw, 16px);
          }
        }

        .social {
          a {
            height: 35px;

            mat-icon {
              width: 35px;
              min-width: 35px;
              height: 35px;
            }
          }

          .text-follow {
            font-size: clamp(16px, 1.5vw, 24px);
          }
        }

        .link-wrapper {
          gap: clamp(1em, 4.5vw, 5em);

          mat-icon {
            min-width: 24px;
          }
        }
      }
    }

    &__ows-info {
      &__list-title {
        font-size: clamp(16px, 1.5vw, 22px);
      }

      .standard-container {
        gap: clamp(1em, 8.5vw, 10em);
      }

      .card {
        padding-block: clamp(1em, 1.5vw, 2em);
      }

      .title-section {
        text-align: left;
        margin-bottom: 0.5em;
      }

      .img-wrapper {
        align-content: center;

        img {
          width: 100%;
          max-width: 552px;
          max-height: 414px;
          border-radius: 10px;
        }
      }
    }

    &__statistic {
      background-color: #f4f9ff;

      .standard-container {
        padding-inline: clamp(1em, 4.5vw, 10em);
      }

      .card {
        strong {
          font-size: 60px;
          line-height: normal;
          font-weight: normal;
          background: -webkit-linear-gradient(102.68deg, #1488cc 0%, #2b32b2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &__get-app {
      overflow: hidden;

      .title-section {
        text-align: left;
      }

      .container-wrapper {
        align-content: center;
      }

      .standard-container {
        gap: clamp(1em, 8.5vw, 10em);

        .img-wrapper {
          img {
            width: 100%;
            max-width: 693.32px;
            max-height: 542px;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }

      .app-link {
        img {
          width: 100%;
          max-width: 210px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .landing-page {
    .content {
      &__top {
        background-color: $background-primary;
      }
      &__contact {
        .section-wrapper {
          .link-wrapper {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 908px) {
  .landing-page {
    .content {
      &__step-request {
        .card-wrapper {
          flex-direction: column;

          .card {
            flex-direction: column;
            height: auto;

            .arrow {
              transform: rotate(90deg);
              margin-inline: 0;
              margin-block: 2em;
            }
          }
        }
      }

      &__admin-service {
        .section-wrapper {
          box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.1);
        }
      }
    }
  }
}

@media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
  .landing-page {
    .content {
      &__ows-info {
        position: relative;
        background-image: url('/assets/imgs/ows-info.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          inset: 0;
          background-color: rgba($color: #ffffff, $alpha: 0.85);
          z-index: 0;
        }

        .img-wrapper {
          display: none;
        }

        .standard-container {
          .container-wrapper {
            z-index: 1;
          }
        }
      }

      &__statistic {
        .card {
          text-align: center;
          background-color: #fff;
          padding-block: 1em;
        }
      }

      &__admin-service {
        .input-search {
          .mat-mdc-form-field {
            background-color: #ffffff;
            box-shadow: none !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            &.mat-form-field-appearance-outline {
              .mat-mdc-form-field-infix {
                min-height: $input-search-height - 10;

                input {
                  font-size: 14px;
                }
              }

              .filter-action {
                &.show-filter {
                  display: none;
                }
              }
            }
          }
        }

        .hide-filter {
          display: block !important;
        }
      }
    }
  }
}

.app-admin-filter + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    width: 570px;
    min-width: 100%;
    max-width: 100%;

    mat-dialog-actions {
      .filter-button {
        min-width: 10em;
      }

      .outline {
        &:not(:disabled) {
          border: 1px solid $color-primary;
        }
      }
    }

    .dialog-header-custom {
      h3 {
        font-size: 30;
        font-weight: bold;
      }
    }
  }
}

.menu-selection + * .cdk-overlay-pane {
  .mat-mdc-menu-panel {
    height: fit-content;
    max-height: 20em;

    .mat-mdc-menu-item {
      mat-icon {
        margin-right: 0;
      }

      &.active {
        background-color: $background-grey;
      }
    }
  }
}
