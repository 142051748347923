@use '../variables' as *;
@use '../mixin/transition' as t;

.group-btn {
  button {
    mat-spinner {
      margin-left: 5px;
      display: inline-block !important;

      circle {
        stroke: #fff !important;
      }
    }
  }
}

button {
  &.bordered {
    border: 1px solid;
  }

  /* button small */
  &.btn-sm {
    min-width: 32px !important;
    border: none !important;
  }

  /* button large */
  &.btn-lg {
    width: 300px;
    border-radius: $rounded-sm !important;
    font-size: 20px;
  }

  &.back-button {
    padding: 0 !important;
    @include t.transition(padding, 100ms, ease-out);

    &:hover {
      padding: 3px 22px !important;
    }
  }
}
