.auto-scroll {
  max-height: 300px;
  overflow: auto;
}

.modern-dialog {
  .mat-mdc-dialog-container {
    border-radius: 15px;
    background: #fff;
    color: white;

    .mat-mdc-dialog-surface {
      border-radius: 10px;

      mat-dialog-content {
        padding: 0;
      }

      .webcam-container {
        overflow: hidden;
      }

      webcam {
        display: flex;

        video {
          transform: scaleX(-1);
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
