@use '../variables' as *;

.dashboard-req-list {
  .filter-wrapper {
    .section-box {
      .filter-button {
        background-color: $black-squeeze-light;
      }
    }

    .search-box {
        background-color: $black-squeeze-light;
    }
  }
}
